import React from "react"
import { Link, useIntl } from "gatsby-plugin-intl"
import { RoundSymbol } from "../components/roundSymbol"
import "./education.scss"
import * as R from "ramda"
import { graphql, useStaticQuery } from "gatsby"
import { IMeta } from "../types/models"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

export default function Education({ data }: any) {
  const intl = useIntl()
  const meta = data
  const getContact = (key: string) =>
    R.pathOr("", ["strapiMeta", "contact", key], meta)

  const intro = R.pipe(
    R.split("\n"),
    R.map((section: string) => {
      if (section.indexOf("{{social}}") > -1) {
        const r = section.split("{{social}}")
        const result = R.insert(
          1,
          (<span>
            <a
              className="hem-external-link"
              target="_blank"
              href={getContact("Weibo")}
            >
              Weibo
            </a>
            、
            <a
              className="hem-external-link"
              target="_blank"
              href={getContact("Xiaohongshu")}
            >
              小红书
            </a>
            、
            <a
              className="hem-external-link"
              target="_blank"
              href={getContact("Twitter")}
            >
              Twitter
            </a>
          </span>)
        )(r)
        return <p key={section}>{result}</p>
      }  else {
        return <p key={section}>{section}</p>
      }
    })
  )(intl.formatMessage({ id: "education.intro" }))
  return (
    <>
      <Seo title={"Education"} />
      <main className="education">
        <div className="education-header PPG-Bold">
          <h1
            dangerouslySetInnerHTML={{
              __html: intl.formatHTMLMessage({ id: "education.header" }),
            }}
          ></h1>
        </div>
        <div className="education-content grid grid-cols-1 md:grid-cols-2">
          <div className="flex order-2 md:order-1 items-stretch border-r border-black">
            <div
              style={{ width: 64 }}
              className="hidden md:flex justify-center p-2.5 border-r border-black"
            >
              <RoundSymbol size={40} borderSize={3} color="black" />
            </div>
            <div className="flex flex-col">
              <div className="flex-1 education-intro p-6 md:p-4 ABC">
                {intro}
              </div>
              <div className="education-schedule text-center">
                <Link to="/education_schedule">
                  <span className="hem-external-link">
                    {intl.formatMessage({ id: "education.schedule" })}
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="education-teacher order-1 md:order-2">
            <StaticImage src="../images/edu.png" alt="Education" />
          </div>
        </div>
        <div className="education-footer grid grid-cols-1 md:grid-cols-4 border-t border-b border-black ABC">
          <div className="education-footer-item">
            <span className="normal">1</span>
            <span className="hover">
              {intl.formatMessage({ id: "education.rate" })}
            </span>
          </div>
          <div className="education-footer-item">
            <span className="normal">210</span>
            <span className="hover">
              {intl.formatMessage({ id: "education.duration" })}
            </span>
          </div>
          <div className="education-footer-item">
            <span className="normal">180</span>
            <span className="hover">
              {intl.formatMessage({ id: "education.lessons" })}
            </span>
          </div>
          <div className="education-footer-item">
            <span className="normal">2</span>
            <span className="hover">
              {intl.formatMessage({ id: "education.hour" })}
            </span>
          </div>
        </div>
      </main>
    </>
  )
}

export const query = graphql`
  {
    strapiMeta {
      contact {
        Bandcamp
        Spotify
        Twitter
        Weibo
        YouTube
        Instagram
        Xiaohongshu
      }
    }
  }
`
